import React from 'react'
import Layout from "../components/Layout"
import SEO from '../components/seo'
import { accessibilityStatementHtml } from "../components/accessibilityStatementContent";

const PrivacyPolicy = () => (
      <Layout>
        <SEO title="Accessibility Statement | Academic Info"/>

        <div dangerouslySetInnerHTML={{ __html: accessibilityStatementHtml }} />
      </Layout>
);

export default PrivacyPolicy
