export const accessibilityStatementHtml = `
  <style>
        .data-ans,.data-label{
            display: block;
        }
        .sr-only{
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0,0,0,0);
            border: 0;
        }
  body{
   font-family:Tahoma,Helvetica,sans-serif;
   font-size:16px;
   line-height:1.5em;
   color:#000;
  }
  a{
   color:#000;
  }
  p{
   margin:0;
  }
  h1{
   font-size: 32px;
   margin:0 0 15px;
   color: #000;
   font-weight: 400;
  }
  h2{
   font-size: 24px;
   margin:0;
   color: #000;
   font-weight: 400;
  }
  .implementsList{
   list-style:none;
   padding:0;
   margin:0;
   line-height:1.1em;
  }
  .implementsList li{
   padding: 12px 0 12px 40px;
   position: relative;
  }
  .implementsList li:before{
   background:none;
  }
  .implementsList svg {
   width: 25px;
   height: 25px;
   vertical-align: top;
   display: inline-block;
   position: absolute;
   left: 0;
   top: 8px;
  }
  .implementsList a{
   text-decoration:none;
  }
  .mb-5 {
   margin-bottom:5px;
  }
  .mb-10{
   margin-bottom:10px;
  }
  .mb-20{
   margin-bottom:20px;
  }
  .mb-30{
   margin-bottom:30px;
  }
  .mb-40{
   margin-bottom:40px;
  }
  .mb-50{
   margin-bottom:50px;
  }
  #statement-main-statement-cont{
   width:90%;
   max-width:1219px;
   margin:0 auto;
   padding:30px 0;
  }
  .statement-cont{
   margin-bottom:30px;
  }
  .statement-cont h2{
   margin-bottom:5px;
  }
  #statement-physical-accessibility{
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   row-gap: 15px;
   column-gap: 20px;
   list-style:none;
   padding:0;
   margin:10px 0 0;
  }
  #statement-physical-accessibility li{
   position:relative;
   padding-left:16px;
  }
  #statement-physical-accessibility li:before{
   content: "";
   width: 8px;
   height: 8px;
   border-radius: 50%;
   position: absolute;
   left: 0;
   top: 9px;
   display: block;
   background-color: #000;
  }
  #statement-contact,
  #statement-company{
   display:inline;
  }
  #statement-contact ul,
  #statement-company ul{
   padding:0;
   margin:0;
   list-style:none;
   line-height:1.4em;
   font-size:16px;
   display:inline;
  }
  #statement-contact ul li,
  #statement-company ul li{
   display:inline;
   position:relative;
  }
  #statement-contact ul li:after,
  #statement-company ul li:after{
   content:",";
  }
  #statement-contact ul li:last-child:after,
  #statement-company ul li:last-child:after{
   content:".";
  }
  #statement-contact li .data-label,
  #statement-company ul li .data-label{
   display:inline;
   font-weight:700;
  }
  #statement-contact li .data-value,
  #statement-company ul li .data-value,
  #statement-contact li .data-ans,
  #statement-company ul li .data-ans{
   display:inline;
  }
  #statement-contact .statement-officer-contact  .data-label{
   display:none;
  }
  #statement-information ul{
   padding:0;
   margin:0;
   list-style:none;
   font-size:16px;
  }
  #statement-information ul li{
   display:block;
   margin-top:10px;
  }
  #statement-information ul li .data-label{
   font-weight:700;
  }
  #statement-information ul li .data-ans{
   display:block;
  }
    </style>
  
  <div id="statement-main-statement-cont">
        <div id="statement-header">
            <h1> Accessibility Statement for <span id="statement-domain">www.academicinfo.net</span></h1>
        </div>  
        <div id="statement-body">
            <div class="statement-cont mb-40">
            <p class="mb-20">
    EqualWeb is committed to promoting <a href="https://www.equalweb.com/">web accessibility</a> for people with disabilities.
    We are continually improving the user experience for all site visitors and applying the relevant accessibility standards.
     EqualWeb has taken careful measure to ensure an excellent user experience, regardless of the assistive technology being used to access this site.
                This website conducted a process to implement an automatic accessibility tool to conform as closely as possible to the standards set by the WCAG 2.2
     <a target="_blank" herf="https://www.w3.org/TR/WCAG21/">(Web content Accessibility Guidelines 2.2)</a> at the AA level. 
                The use of automatic accessibility widget  accordance with our <a target="_blank" href="https://www.equalweb.com/html5/?_id=10016&did=1116&G=">Terms of Service (AS IS)</a>.
                Measures taken to make this website more accessible
                EqualWeb's automatic accessibility tool implements the following measures to websites on which it is installed:
            </p>
                <ul class="implementsList">
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="149.848px" height="98.323px" viewBox="0 0 149.848 98.323" xml:space="preserve">
      <path d="M41.418,51.493H5.412C2.423,51.493,0,53.917,0,56.905v36.007c0,2.986,2.423,5.409,5.412,5.409l36.006,0.002
      c2.989-0.004,5.408-2.421,5.412-5.411V56.905C46.826,53.912,44.407,51.496,41.418,51.493z M39.83,91.322H7l0.002-32.829H39.83
      V91.322z M24.111,85.87c0.684,0.683,1.58,1.023,2.475,1.023c0.896,0,1.792-0.341,2.476-1.023c1.365-1.367,1.365-3.584,0-4.949
      l-0.002-0.002l-6.012-6.012l6.012-6.012c1.367-1.367,1.367-3.584,0.002-4.948c-1.367-1.367-3.583-1.367-4.949,0l-8.491,8.487
      c-0.652,0.649-1.025,1.552-1.025,2.475c0,0.92,0.374,1.821,1.025,2.474L24.111,85.87z M144.436,51.493H108.43
      c-2.991,0.003-5.406,2.419-5.41,5.412v36.007c0.002,2.99,2.423,5.407,5.41,5.409v0.002h36.006c2.986-0.006,5.412-2.423,5.412-5.411
      V56.905C149.848,53.915,147.424,51.496,144.436,51.493z M142.848,91.322H110.02V58.493h32.828V91.322z M120.787,85.869
      c0.684,0.684,1.58,1.025,2.477,1.025c0.895,0,1.791-0.342,2.475-1.023l8.486-8.489c0.66-0.66,1.025-1.539,1.025-2.476
      c0-0.934-0.365-1.813-1.025-2.473l-8.488-8.487c-1.367-1.367-3.584-1.367-4.949,0c-1.367,1.367-1.367,3.583,0,4.95l6.012,6.01
      l-6.012,6.014C119.42,82.285,119.42,84.502,120.787,85.869z M92.945,51.493H56.938c-2.993,0.003-5.41,2.422-5.412,5.412v36.007
      c0.002,2.988,2.425,5.409,5.412,5.409l36.008,0.002c2.987-0.002,5.408-2.423,5.41-5.411V56.905
      C98.352,53.915,95.937,51.498,92.945,51.493z M91.354,91.322H58.525V58.493h32.83L91.354,91.322z M72.467,82.698
      c0.66,0.662,1.541,1.027,2.476,1.027c0.934-0.002,1.813-0.365,2.475-1.027l8.485-8.487c1.366-1.367,1.366-3.584,0-4.948
      c-1.368-1.367-3.584-1.367-4.95,0l-6.012,6.011l-6.012-6.011c-1.369-1.369-3.584-1.369-4.95,0c-1.367,1.366-1.367,3.581,0,4.95
      L72.467,82.698z M56.937,46.828l36.007,0.002c2.993-0.004,5.408-2.425,5.411-5.413V5.412C98.349,2.425,95.934,0,92.944,0H56.937
      c-2.987,0.002-5.409,2.419-5.411,5.412v36.005C51.53,44.409,53.945,46.824,56.937,46.828z M58.526,6.999l32.83,0.002v32.828h-32.83
      V6.999z M77.415,15.623c-0.651-0.652-1.554-1.025-2.476-1.025c-0.921,0-1.824,0.373-2.476,1.025l-8.485,8.489
      c-1.367,1.366-1.367,3.582,0,4.95c0.684,0.684,1.58,1.024,2.475,1.024c0.896,0.001,1.792-0.342,2.476-1.026l6.01-6.014l6.014,6.014
      c1.366,1.366,3.582,1.366,4.949,0c1.368-1.366,1.368-3.582,0.002-4.948L77.415,15.623z"></path>
      </svg>Enables keyboard navigation
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="81.216px" height="126.878px" viewBox="0 0 81.216 126.878" xml:space="preserve">
      <path d="M25.049,21.606c0.684,0,1.367-0.261,1.889-0.782v-0.002L41.312,6.446l14.375,14.376c1.041,1.043,2.732,1.043,3.775,0.002
      c1.043-1.043,1.045-2.734,0.002-3.777L43.201,0.783c-1.043-1.043-2.734-1.046-3.777,0L23.161,17.047
      c-1.043,1.043-1.043,2.732,0,3.777C23.683,21.346,24.366,21.606,25.049,21.606z M55.686,106.056l-14.375,14.375l-14.375-14.375
      c-1.043-1.043-2.734-1.043-3.777,0c-1.042,1.044-1.042,2.734,0.002,3.777l16.262,16.262c0.505,0.505,1.175,0.783,1.889,0.783
      s1.384-0.278,1.889-0.783l16.264-16.264c1.042-1.043,1.042-2.731-0.002-3.775C58.419,105.013,56.73,105.013,55.686,106.056z
      M80.431,93.841L47.327,27.287c-1.269-2.549-3.87-4.158-6.716-4.158c-2.847,0-5.449,1.609-6.714,4.158L0.787,93.841
      c-1.846,3.707-0.335,8.21,3.375,10.056c3.708,1.843,8.209,0.333,10.055-3.375l5.579-11.214h41.625L67,100.521
      c1.312,2.635,3.965,4.16,6.721,4.16c1.122,0,2.261-0.252,3.334-0.785C80.763,102.051,82.275,97.55,80.431,93.841z M27.258,74.308
      l13.353-26.84l13.35,26.84H27.258z"></path>
      </svg>Fonts - Ability to increase and decrease the site font, adjust, align etc'
                    </li>
                    <li>
     <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="77.501px" height="80.057px" viewBox="0 0 77.501 80.057" xml:space="preserve">
      <path d="M76.881,57.929c-0.743-0.88-1.992-1.206-3.092-0.827c-3.081,1.061-6.424,1.607-9.941,1.607
      c-16.84,0-30.538-13.173-30.538-29.355c0-9.741,5.004-18.808,13.382-24.262c0.949-0.62,1.402-1.735,1.148-2.809
      c-0.254-1.071-1.172-1.884-2.309-2.043C44.231,0.058,42.938,0,41.64,0C18.676,0,0,17.954,0,40.028s18.676,40.028,41.64,40.028
      c14.572,0,27.829-7.12,35.454-19.043C77.708,60.048,77.62,58.815,76.881,57.929z"></path>
      </svg>Change color contrast based on dark background
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="127.003px" height="127.002px" viewBox="0 0 127.003 127.002" xml:space="preserve">
      <path d="M63.502,24.318c-21.64,0.004-39.178,17.542-39.18,39.184c0.002,21.64,17.54,39.178,39.18,39.182
      c21.64-0.004,39.18-17.542,39.183-39.182C102.682,41.86,85.142,24.322,63.502,24.318z M63.502,95.682
      c-17.771-0.032-32.148-14.408-32.18-32.18C31.354,45.729,45.73,31.35,63.502,31.32c17.771,0.029,32.149,14.408,32.182,32.182
      C95.651,81.273,81.273,95.649,63.502,95.682z M63.502,110.851c-1.933,0-3.5,1.565-3.5,3.499v9.153c0,1.932,1.567,3.499,3.5,3.499
      c1.932,0,3.501-1.567,3.501-3.499v-9.153C67.003,112.416,65.434,110.851,63.502,110.851z M63.501,16.151
      c1.934,0,3.502-1.565,3.502-3.499V3.501C67.003,1.567,65.435,0,63.501,0c-1.933,0-3.499,1.567-3.499,3.501v9.151
      C60.002,14.586,61.568,16.151,63.501,16.151z M14.47,60.002H3.501C1.567,60.002,0,61.568,0,63.501s1.567,3.501,3.501,3.501H14.47
      c1.933,0,3.499-1.568,3.499-3.501S16.403,60.002,14.47,60.002z M123.503,60.002h-10.969c-1.932,0-3.499,1.566-3.499,3.499
      s1.567,3.501,3.499,3.501h10.969c1.933,0,3.5-1.568,3.5-3.501S125.436,60.002,123.503,60.002z M25.741,96.313l-7.142,7.142
      c-1.367,1.366-1.367,3.583,0,4.949c0.683,0.684,1.58,1.025,2.476,1.025c0.895,0,1.791-0.341,2.473-1.025l7.143-7.14
      c1.368-1.366,1.368-3.583,0.002-4.949C29.326,94.946,27.109,94.946,25.741,96.313z M98.789,31.714c0.896,0,1.792-0.341,2.476-1.024
      l7.139-7.14c1.367-1.369,1.367-3.584,0-4.95c-1.366-1.366-3.583-1.366-4.949,0l-7.141,7.14c-1.366,1.366-1.366,3.582,0,4.95
      C96.997,31.373,97.894,31.714,98.789,31.714z M101.263,96.313c-1.366-1.366-3.582-1.366-4.951,0c-1.366,1.366-1.366,3.582,0,4.949
      l7.143,7.142c0.684,0.684,1.578,1.025,2.475,1.025c0.896,0,1.791-0.342,2.475-1.025c1.366-1.368,1.366-3.583,0-4.951L101.263,96.313
      z M25.741,30.689c0.683,0.684,1.579,1.025,2.474,1.025c0.896,0,1.792-0.342,2.476-1.025c1.366-1.366,1.366-3.584,0-4.95l-7.144-7.14
      c-1.367-1.368-3.582-1.366-4.948,0c-1.368,1.366-1.366,3.584,0,4.95L25.741,30.689z"></path>
      </svg>Change color contrast based on a light background
                    </li>
                    <li>
     <svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="103.277px" height="124.513px" viewBox="0 0 103.277 124.513" xml:space="preserve">
      <path fill="#010101" d="M95.281,49.057C81.262,25.063,54.081,1.02,53.965,0.884c-0.003-0.003-0.007-0.005-0.011-0.008
      c-0.082-0.073-0.174-0.138-0.264-0.203c-0.051-0.036-0.099-0.077-0.15-0.111c-0.051-0.032-0.106-0.059-0.159-0.089
      c-0.098-0.057-0.195-0.113-0.297-0.16c-0.004-0.002-0.007-0.004-0.011-0.006c-0.03-0.014-0.061-0.021-0.09-0.033
      c-0.124-0.051-0.248-0.1-0.375-0.136c-0.047-0.014-0.094-0.021-0.14-0.032c-0.114-0.027-0.228-0.055-0.343-0.071
      c-0.069-0.01-0.139-0.011-0.208-0.017C51.826,0.01,51.733,0,51.64,0s-0.186,0.01-0.279,0.018c-0.069,0.006-0.139,0.007-0.208,0.017
      c-0.116,0.017-0.229,0.044-0.343,0.071c-0.046,0.012-0.093,0.019-0.14,0.032c-0.127,0.036-0.251,0.085-0.375,0.136
      c-0.03,0.013-0.06,0.02-0.09,0.033c-0.004,0.002-0.007,0.004-0.011,0.006c-0.102,0.047-0.199,0.104-0.297,0.16
      c-0.053,0.03-0.108,0.057-0.159,0.089c-0.052,0.034-0.1,0.075-0.15,0.111c-0.09,0.065-0.181,0.13-0.264,0.203
      c-0.003,0.003-0.007,0.005-0.01,0.008c-0.068,0.063-12.19,10.832-24.399,25.053c-6.105,7.117-12.239,15.092-16.916,23.12
      C3.345,57.097,0.036,65.166,0,72.874c0.002,28.52,23.12,51.635,51.641,51.639c28.519-0.004,51.634-23.119,51.636-51.639
      C103.244,65.166,99.935,57.097,95.281,49.057z M7.002,72.874c-0.036-5.588,2.674-12.835,7.044-20.294
      c6.523-11.218,16.542-22.925,24.902-31.731c3.47-3.66,6.655-6.828,9.19-9.278v105.806c-10.938-0.847-20.746-5.627-28.063-12.938
      C11.992,96.348,7.002,85.211,7.002,72.874z"></path>
      </svg>Change the Site's colors
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="87.056px" height="87.057px" viewBox="0 0 87.056 87.057" xml:space="preserve">
      <path d="M43.526,0C19.486,0.002,0.002,19.487,0,43.528c0.002,24.041,19.486,43.524,43.526,43.528
      c24.043-0.004,43.526-19.487,43.529-43.528C87.053,19.487,67.569,0.002,43.526,0z M47.028,7.171
      c0.881,0.084,1.751,0.203,2.613,0.348v72.018c-0.862,0.146-1.732,0.265-2.613,0.348V7.171z M56.642,9.435
      c0.889,0.342,1.764,0.711,2.616,1.118v65.95c-0.853,0.407-1.728,0.776-2.616,1.118V9.435z M66.257,14.944
      c0.932,0.742,1.825,1.527,2.68,2.355c-0.037,0.203-0.063,0.411-0.063,0.626V69.6c0,0.068,0.016,0.132,0.02,0.198
      c-0.842,0.813-1.721,1.584-2.637,2.313V14.944z M75.872,26.557c1.076,2.044,1.963,4.2,2.639,6.45
      c-0.005,0.075-0.022,0.146-0.022,0.222V53.95c0,0.045,0.012,0.086,0.013,0.131c-0.674,2.238-1.559,4.384-2.629,6.418V26.557z
      M7,43.528c0-10.098,4.083-19.21,10.697-25.829c5.847-5.843,13.636-9.703,22.33-10.528v72.714
      c-8.694-0.825-16.483-4.685-22.33-10.527C11.083,62.735,7,53.626,7,43.528z"></path>
      </svg>Matching and monochrome option for color blind people
                    </li>
    
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="121.942px" height="125.554px" viewBox="0 0 121.942 125.554" xml:space="preserve">
      <path d="M17.228,95.437c1.073,0.534,2.213,0.787,3.336,0.787c2.756,0,5.409-1.525,6.721-4.161l7.263-14.602h52.848l7.264,14.602
      c1.845,3.707,6.346,5.219,10.055,3.374c3.708-1.844,5.219-6.345,3.375-10.055l-40.4-81.223C66.42,1.61,63.82,0,60.973,0
      c-2.846,0-5.445,1.61-6.715,4.159L13.854,85.382C12.01,89.089,13.521,93.591,17.228,95.437z M60.973,24.339l18.963,38.122H42.01
      L60.973,24.339z M114.443,110.555H7.501c-4.144,0-7.501,3.357-7.501,7.499s3.358,7.5,7.501,7.5h106.942
      c4.141,0,7.499-3.358,7.499-7.5S118.584,110.555,114.443,110.555z"></path>
      </svg>Change the font for readability
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="93.647px" height="127.401px" viewBox="0 0 93.647 127.401" xml:space="preserve">
      <path d="M53.389,127.257c-0.89-0.264-1.627-0.858-2.072-1.676l0,0l-20.749-38.16L8.229,110.619
      c-0.978,1.014-2.464,1.339-3.776,0.826l0,0c-1.31-0.514-2.183-1.76-2.212-3.167l0,0l-1.12-52.361L0.001,3.555
      c-0.028-1.301,0.677-2.513,1.818-3.132l0,0C2.959-0.199,4.36-0.131,5.436,0.6l0,0L48.778,30l43.344,29.4l0,0
      c1.164,0.79,1.736,2.2,1.453,3.58l0,0c-0.28,1.378-1.362,2.449-2.744,2.717l0,0L59.22,71.84l20.752,38.159
      c0.443,0.817,0.543,1.757,0.279,2.649l0,0c-0.262,0.892-0.857,1.627-1.674,2.071l0,0l-22.539,12.256
      c-0.518,0.281-1.086,0.426-1.661,0.426l0,0C54.047,127.401,53.715,127.354,53.389,127.257L53.389,127.257z M31.823,78.139
      c1.095,0.146,2.063,0.813,2.592,1.787l0,0l21.355,39.272l16.425-8.931L50.838,70.992c-0.528-0.972-0.563-2.146-0.092-3.146l0,0
      c0.471-0.999,1.399-1.723,2.483-1.934l0,0l28.055-5.452l-36.414-24.7L7.105,10.144l0.976,45.624l0.941,43.99l19.826-20.586
      c0.658-0.684,1.57-1.064,2.509-1.064l0,0C31.512,78.107,31.667,78.117,31.823,78.139L31.823,78.139z"></path>
      </svg>Increase the cursor and change its color to black or white
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="131.859px" height="131.861px" viewBox="0 0 131.859 131.861" xml:space="preserve">
      <path d="M128.781,108.985L97.308,77.51c4.254-7.538,6.692-16.236,6.693-25.509C103.998,23.28,80.721,0.004,51.999,0
      C23.28,0.004,0.002,23.28,0,52.001c0.002,28.72,23.278,51.997,51.999,52.002c9.273-0.002,17.971-2.44,25.509-6.693l31.478,31.476
      c2.039,2.041,4.746,3.078,7.424,3.076h0.016c2.67,0,5.372-1.037,7.408-3.076l4.95-4.949c2.043-2.045,3.08-4.75,3.076-7.425
      C131.863,113.733,130.826,111.026,128.781,108.985z M20.181,83.819C12.031,75.666,7.002,64.439,7,52.001
      c0.002-12.438,5.032-23.666,13.181-31.82c8.154-8.147,19.38-13.179,31.818-13.179c12.438,0,23.667,5.031,31.82,13.179
      c8.148,8.154,13.178,19.383,13.18,31.82c-0.001,6.755-1.495,13.147-4.156,18.89L71.294,21.7h-8.962L33.951,86.487h12.548
      l5.377-12.957H81.75l3.601,8.678c-0.497,0.549-1.007,1.087-1.531,1.611c-8.153,8.15-19.382,13.18-31.82,13.182
      C39.561,96.999,28.335,91.97,20.181,83.819z M77.985,62.929H55.641l11.172-29.567L77.985,62.929z M123.831,118.888l-4.947,4.945
      c-0.692,0.692-1.568,1.022-2.475,1.027c-0.906-0.002-1.783-0.335-2.474-1.027L83.481,93.38c3.727-2.84,7.059-6.171,9.898-9.898
      l30.453,30.454c0.69,0.692,1.023,1.567,1.027,2.476C124.856,117.315,124.523,118.191,123.831,118.888z"></path>
      </svg>Increase the display to 200%
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="139.76px" height="125.288px" viewBox="0 0 139.76 125.288" xml:space="preserve">
      <path d="M125.297,27.496c0-3.546-1.368-6.879-3.855-9.386l-14.099-14.22C104.856,1.382,101.552,0,98.035,0s-6.822,1.382-9.308,3.889
      l-63.23,63.775c-3.766,3.795-5.836,8.843-5.836,14.213c0,2.269-0.913,4.49-2.505,6.096l-5.437,5.483
      c-1.415,1.428-2.227,3.402-2.227,5.421c0,1.318,0.35,2.619,0.982,3.764l-9.4,9.48c-0.776,0.785-1.165,1.88-1.058,2.984
      c0.11,1.104,0.704,2.099,1.617,2.715l9.437,6.344c0.621,0.419,1.331,0.623,2.037,0.623c0.945,0,1.885-0.367,2.596-1.084l7.828-7.896
      c1.133,0.642,2.423,0.994,3.73,0.994c2,0,3.961-0.821,5.375-2.248l5.438-5.483c1.59-1.604,3.794-2.523,6.043-2.523
      c5.324,0,10.328-2.092,14.093-5.888l63.23-63.773C123.929,34.376,125.297,31.044,125.297,27.496z M44.118,99.142
      c-4.243,0-8.232,1.666-11.234,4.692l-5.437,5.484c0,0,0,0-0.001,0c-0.097,0.1-0.271,0.098-0.369,0l-9.016-9.093c0,0,0,0,0-0.002
      l-1.151-1.159c-0.115-0.117-0.091-0.284,0-0.374l5.438-5.482c3.003-3.028,4.654-7.052,4.654-11.331c0-1.687,0.323-3.322,0.94-4.835
      l20.97,21.149C47.412,98.813,45.79,99.142,44.118,99.142z M54.785,93.642L32.454,71.118l53.785-54.247l22.332,22.522L54.785,93.642z
      M98.145,118.288v-0.003h-8.679c-3.173,0-6.015-1.275-8.097-3.354c-2.081-2.086-3.354-4.927-3.356-8.099
      c0.003-3.175,1.278-6.018,3.354-8.1c2.085-2.081,4.927-3.356,8.1-3.356h8.679c1.934,0,3.5-1.566,3.5-3.5s-1.566-3.5-3.5-3.5h-8.679
      c-5.085-0.003-9.72,2.071-13.048,5.405c-3.334,3.328-5.408,7.964-5.406,13.051c-0.002,5.084,2.072,9.72,5.406,13.047
      c3.328,3.335,7.963,5.408,13.048,5.406h8.679c1.934,0,3.5-1.566,3.5-3.5S100.078,118.288,98.145,118.288z M134.355,93.782
      c-3.327-3.334-7.965-5.408-13.049-5.405h-8.677c-1.934,0-3.503,1.566-3.503,3.5s1.569,3.5,3.503,3.5h8.677
      c3.174,0,6.015,1.275,8.099,3.354c2.078,2.087,3.354,4.928,3.356,8.103c-0.003,3.172-1.278,6.015-3.356,8.097
      c-2.084,2.08-4.925,3.355-8.099,3.358l-8.677-0.003c-1.934,0-3.503,1.567-3.503,3.501s1.569,3.502,3.503,3.502l8.677-0.002
      c5.084,0.002,9.722-2.071,13.049-5.406c3.335-3.327,5.406-7.963,5.404-13.047C139.762,101.746,137.69,97.11,134.355,93.782z
      M88.136,106.643c0,1.932,1.568,3.5,3.501,3.5h27.5c1.932,0,3.5-1.568,3.5-3.5c0-1.935-1.568-3.501-3.5-3.501h-27.5
      C89.704,103.142,88.136,104.708,88.136,106.643z"></path>
      </svg>Highlight links on the site
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="124.787px" height="125.286px" viewBox="0 0 124.787 125.286" xml:space="preserve">
      <path d="M120.948,18.107l-14.043-14.22C104.427,1.38,101.137,0,97.634,0c-3.501,0-6.792,1.38-9.269,3.888L25.393,67.663
      c-3.75,3.795-5.815,8.843-5.815,14.213c0,2.269-0.908,4.488-2.491,6.093l-5.418,5.484c-1.407,1.43-2.216,3.404-2.216,5.423
      c0,1.319,0.348,2.62,0.98,3.764l-9.362,9.48c-0.774,0.785-1.162,1.88-1.053,2.982c0.107,1.104,0.699,2.101,1.609,2.715l9.396,6.347
      c0.62,0.418,1.326,0.622,2.027,0.622c0.945,0,1.879-0.37,2.587-1.084l7.797-7.896c1.129,0.642,2.412,0.992,3.717,0.992
      c1.991,0,3.943-0.817,5.352-2.246l5.417-5.483c1.584-1.604,3.776-2.523,6.017-2.523c5.303,0,10.288-2.092,14.035-5.887
      l62.976-63.776c2.475-2.507,3.839-5.839,3.839-9.387C124.787,23.949,123.422,20.616,120.948,18.107z M43.937,99.141
      c-4.225,0-8.2,1.666-11.189,4.69l-5.414,5.486c0,0,0,0-0.001,0c-0.096,0.098-0.268,0.098-0.367,0l-8.979-9.095l-1.146-1.161
      c-0.114-0.115-0.09-0.281,0-0.372l5.416-5.482c2.989-3.028,4.635-7.052,4.635-11.331c0-1.687,0.321-3.322,0.937-4.837l20.884,21.149
      C47.217,98.812,45.604,99.141,43.937,99.141z M54.561,93.639l-22.24-22.521l53.566-54.248l22.242,22.523L54.561,93.639z
      M82.516,121.786c0,1.934,1.567,3.5,3.5,3.5c1.934,0,3.501-1.566,3.501-3.5v-13.264h17.5v13.264c0,1.934,1.566,3.5,3.498,3.5
      c1.934,0,3.5-1.566,3.5-3.5V88.264c0-1.934-1.566-3.5-3.5-3.5c-1.932,0-3.498,1.566-3.498,3.5v13.26h-17.5v-13.26
      c0-1.934-1.567-3.5-3.501-3.5c-1.933,0-3.5,1.566-3.5,3.5"></path>
      </svg>Highlighting headers on the site
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="119.589px" height="132.002px" viewBox="0 0 119.589 132.002" xml:space="preserve">
      <path d="M110.089,0H9.5C4.251,0.002,0.002,4.252,0,9.502V122.5c0.002,5.249,4.251,9.498,9.5,9.5l100.589,0.002
      c5.25-0.004,9.498-4.253,9.5-9.502V9.502C119.587,4.254,115.339,0.002,110.089,0z M112.59,122.5
      c-0.002,1.381-1.12,2.499-2.501,2.501H9.5c-1.378-0.002-2.499-1.122-2.501-2.501V9.502C7.001,8.122,8.122,7.004,9.5,7.001h100.589
      c1.381,0.003,2.499,1.121,2.501,2.501V122.5z M96.38,102.97H23.214c-1.676,0-3.031,1.357-3.031,3.031s1.355,3.031,3.031,3.031H96.38
      c1.674,0,3.031-1.357,3.031-3.031S98.054,102.97,96.38,102.97z M96.378,22.97H23.212c-0.798,0-1.58,0.323-2.143,0.889
      c-0.565,0.563-0.887,1.345-0.887,2.144v53.333c0,0.809,0.316,1.569,0.887,2.144c0.574,0.571,1.334,0.887,2.143,0.887h73.166
      c0.812,0,1.571-0.315,2.144-0.887c0.573-0.574,0.89-1.335,0.89-2.144V26.002c0-0.799-0.324-1.58-0.89-2.144
      C97.958,23.293,97.177,22.97,96.378,22.97z M93.349,60.079L80.251,46.984c-1.184-1.185-3.102-1.187-4.285,0L61.62,61.33
      L45.448,38.736c-0.536-0.747-1.376-1.211-2.295-1.262s-1.808,0.315-2.422,1.001L26.245,54.592v-25.56h67.104V60.079z"></path>
      </svg>Display an alternative description of the images
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="131.859px" height="131.861px" viewBox="0 0 131.859 131.861" xml:space="preserve">
      <path d="M128.781,108.985L97.308,77.51c4.254-7.538,6.692-16.236,6.693-25.509C103.998,23.28,80.721,0.004,51.999,0
      C23.28,0.004,0.002,23.28,0,52.001c0.002,28.72,23.278,51.997,51.999,52.002c9.273-0.002,17.971-2.44,25.509-6.693l31.478,31.476
      c2.039,2.041,4.746,3.078,7.424,3.076h0.016c2.67,0,5.372-1.037,7.408-3.076l4.95-4.949c2.043-2.045,3.08-4.75,3.076-7.425
      C131.863,113.733,130.826,111.026,128.781,108.985z M20.181,83.819C12.031,75.666,7.002,64.439,7,52.001
      c0.002-12.438,5.032-23.666,13.181-31.82c8.154-8.147,19.38-13.179,31.818-13.179c12.438,0,23.667,5.031,31.82,13.179
      c8.148,8.154,13.178,19.383,13.18,31.82c-0.001,6.755-1.495,13.147-4.156,18.89L71.294,21.7h-8.962L33.951,86.487h12.548
      l5.377-12.957H81.75l3.601,8.678c-0.497,0.549-1.007,1.087-1.531,1.611c-8.153,8.15-19.382,13.18-31.82,13.182
      C39.561,96.999,28.335,91.97,20.181,83.819z M77.985,62.929H55.641l11.172-29.567L77.985,62.929z M123.831,118.888l-4.947,4.945
      c-0.692,0.692-1.568,1.022-2.475,1.027c-0.906-0.002-1.783-0.335-2.474-1.027L83.481,93.38c3.727-2.84,7.059-6.171,9.898-9.898
      l30.453,30.454c0.69,0.692,1.023,1.567,1.027,2.476C124.856,117.315,124.523,118.191,123.831,118.888z"></path>
      </svg>Increase the content chosen by the cursor, showed in a tooltip
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="131.999px" height="132.303px" viewBox="0 0 131.999 132.303" xml:space="preserve">
      <path fill="#010101" d="M131.25,5.802c-0.48-1.14-1.175-2.162-2.033-3.019c-0.856-0.856-1.879-1.553-3.018-2.034
      C125.061,0.266,123.803,0,122.5,0c0,0-21.187,0-28.249,0c-2.46,0.021-5.21,0.666-8.348,1.539C78.491,3.643,69.401,7.305,66,8.709
      c-1.984-0.818-5.903-2.403-10.28-3.998c-3.142-1.144-6.515-2.292-9.623-3.172C42.96,0.666,40.21,0.021,37.75,0H9.5
      C8.198,0,6.939,0.266,5.8,0.749C4.66,1.23,3.638,1.927,2.782,2.785C1.925,3.64,1.231,4.662,0.748,5.802C0.266,6.941,0,8.197,0,9.501
      V113.5c0,1.302,0.266,2.561,0.748,3.698c0.483,1.142,1.178,2.161,2.036,3.02c0.854,0.856,1.876,1.551,3.016,2.034
      C6.939,122.733,8.198,123,9.5,123c0,0,21.188,0,28.251,0c1.071-0.021,3.619,0.458,6.438,1.273c4.281,1.212,9.436,3.082,13.474,4.644
      c4.044,1.563,6.96,2.803,6.969,2.805L66,132.303l1.369-0.581c0.003-0.002,1.288-0.548,3.358-1.383
      c3.104-1.248,7.939-3.126,12.584-4.671c4.58-1.557,9.264-2.72,10.939-2.668c7.063,0,28.249,0,28.249,0
      c1.304,0,2.562-0.267,3.7-0.748c1.139-0.483,2.161-1.178,3.02-2.038c0.856-0.854,1.551-1.874,2.031-3.016
      c0.484-1.138,0.75-2.396,0.748-3.698V9.501C132,8.197,131.735,6.941,131.25,5.802z M62.5,123.295c-1.93-0.765-4.272-1.67-6.78-2.583
      c-3.142-1.144-6.515-2.292-9.623-3.174c-3.138-0.871-5.887-1.516-8.347-1.537c-3.532-0.001-10.587-0.002-16.763-0.002
      S9.514,116,9.5,116.001c-0.356-0.002-0.675-0.07-0.972-0.196c-0.296-0.126-0.566-0.309-0.792-0.534
      c-0.232-0.232-0.416-0.503-0.541-0.799c-0.126-0.299-0.194-0.618-0.196-0.972V9.501c0.002-0.355,0.07-0.675,0.196-0.974
      C7.32,8.233,7.503,7.961,7.733,7.733c0.228-0.23,0.499-0.413,0.794-0.539C8.824,7.068,9.144,7.001,9.5,6.998c0,0,21.188,0,28.251,0
      c1.071-0.021,3.619,0.461,6.438,1.276c4.281,1.21,9.436,3.082,13.474,4.644c1.939,0.748,3.613,1.421,4.837,1.92V123.295z M125,113.5
      c0,0.354-0.07,0.673-0.197,0.972c-0.123,0.296-0.309,0.566-0.536,0.797c-0.228,0.228-0.498,0.41-0.794,0.536
      c-0.299,0.126-0.618,0.194-0.974,0.196c0-0.002-21.187-0.002-28.249,0c-2.46,0.021-5.21,0.666-8.348,1.537
      c-5.559,1.58-12.063,4.035-16.402,5.755V14.838c0.371-0.151,0.776-0.316,1.227-0.498c3.104-1.248,7.939-3.126,12.584-4.671
      c4.58-1.557,9.264-2.72,10.939-2.671c7.063,0,28.249,0,28.249,0c0.355,0.003,0.675,0.07,0.974,0.196
      c0.296,0.126,0.566,0.309,0.792,0.535c0.229,0.231,0.415,0.504,0.538,0.798C124.93,8.826,125,9.146,125,9.501V113.5z M47.285,50.988
      H37.257v-2.507c0-1.386-1.123-2.507-2.508-2.507s-2.506,1.121-2.506,2.507v2.507H22.216c-1.387,0-2.508,1.122-2.508,2.507
      s1.121,2.508,2.508,2.508h16.77c-0.924,2.647-2.268,5.129-3.984,7.35c-1.16-1.158-2.225-2.405-3.188-3.73
      c-0.777-1.147-2.334-1.447-3.482-0.675c-1.141,0.771-1.445,2.313-0.689,3.461c1.154,1.625,2.449,3.15,3.865,4.555
      c-2.24,1.867-4.814,3.294-7.584,4.209c-1.316,0.438-2.027,1.858-1.588,3.173c0.434,1.315,1.857,2.027,3.174,1.591
      c3.59-1.178,6.91-3.067,9.756-5.557c2.59,2.109,5.404,3.931,8.393,5.428c1.24,0.619,2.746,0.116,3.363-1.124
      c0.621-1.238,0.117-2.744-1.121-3.362c-2.535-1.279-4.926-2.821-7.139-4.599c2.5-3.186,4.361-6.827,5.48-10.719h3.043
      c1.385,0,2.508-1.123,2.508-2.508S48.669,50.988,47.285,50.988z M100.383,47.796c-0.591-1.188-1.806-1.94-3.133-1.94
      c-1.328,0-2.543,0.752-3.133,1.94L82.509,71.131c-0.861,1.73-0.157,3.832,1.574,4.693c0.501,0.248,1.032,0.366,1.557,0.366
      c1.286,0,2.524-0.711,3.137-1.94v-0.002l1.714-3.446h13.518l1.715,3.446c0.861,1.73,2.963,2.436,4.693,1.576
      c1.73-0.861,2.436-2.963,1.574-4.693L100.383,47.796z M93.973,63.801l3.275-6.587l3.277,6.587H93.973z"></path>
      </svg>Describe words by mouse selection  
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="132.002px" viewBox="0 0 100 132.002" xml:space="preserve">
      <path fill="#010101" d="M90.5,0L9.499,0.002C4.251,0.004,0.002,4.253,0,9.501v113c0.002,5.248,4.251,9.497,9.499,9.501L90.5,132
      c5.248-0.002,9.498-4.251,9.5-9.499v-113C99.998,4.253,95.748,0.004,90.5,0z M92.999,122.501c-0.003,1.378-1.12,2.498-2.499,2.498
      l-81.001,0.003c-1.378-0.003-2.496-1.123-2.499-2.501l-0.002-113c0.004-1.378,1.123-2.498,2.5-2.498L90.5,7
      c1.379,0.003,2.496,1.123,2.499,2.501V122.501z M68,26.47H32c-1.674,0-3.031,1.356-3.031,3.031c0,1.674,1.357,3.031,3.031,3.031h36
      c1.673,0,3.03-1.357,3.03-3.031C71.03,27.826,69.673,26.47,68,26.47z M66.75,55.138H21.666c-1.673,0-3.031,1.356-3.031,3.031
      c0,1.673,1.358,3.03,3.031,3.03H66.75c1.673,0,3.031-1.357,3.031-3.03C69.781,56.494,68.423,55.138,66.75,55.138z M76.335,61.199
      h1.998c1.673,0,3.03-1.357,3.03-3.03c0-1.675-1.357-3.031-3.03-3.031h-1.998c-1.675,0-3.033,1.356-3.033,3.031
      C73.302,59.842,74.66,61.199,76.335,61.199z M21.667,73.865h45.082c1.675,0,3.031-1.357,3.031-3.031
      c0-1.675-1.356-3.031-3.031-3.031H21.667c-1.675,0-3.031,1.356-3.031,3.031C18.636,72.508,19.992,73.865,21.667,73.865z
      M76.334,67.803c-1.674,0-3.031,1.356-3.031,3.031c0,1.674,1.357,3.031,3.031,3.031h1.998c1.676,0,3.031-1.357,3.031-3.031
      c0-1.675-1.355-3.031-3.031-3.031H76.334z M66.75,80.47H21.667c-1.676,0-3.031,1.356-3.031,3.031c0,1.674,1.355,3.029,3.031,3.029
      H66.75c1.674,0,3.031-1.355,3.031-3.029C69.781,81.826,68.424,80.47,66.75,80.47z M78.333,80.47h-1.999
      c-1.674,0-3.032,1.356-3.032,3.031c0,1.674,1.358,3.029,3.032,3.029h1.999c1.674,0,3.032-1.355,3.032-3.029
      C81.365,81.826,80.007,80.47,78.333,80.47z M66.749,93.137H21.667c-1.674,0-3.032,1.356-3.032,3.03s1.358,3.031,3.032,3.031h45.082
      c1.674,0,3.032-1.357,3.032-3.031S68.423,93.137,66.749,93.137z M78.333,93.137h-1.999c-1.674,0-3.031,1.356-3.031,3.03
      s1.357,3.031,3.031,3.031h1.999c1.674,0,3.03-1.357,3.03-3.031S80.007,93.137,78.333,93.137z"></path>
      </svg>Displays the site's contents in a new window clearly and readable
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="155.66px" height="97.318px" viewBox="0 0 155.66 97.318" xml:space="preserve">
      <path d="M72.927,44.19h9.809c2.157,0,3.906-1.749,3.906-3.91v-9.804c0-2.164-1.745-3.909-3.906-3.909h-9.809
      c-2.161,0-3.906,1.745-3.908,3.909v9.804C69.021,42.441,70.768,44.19,72.927,44.19z M72.93,17.627h9.805
      c2.159-0.003,3.908-1.753,3.91-3.91V3.909c-0.002-2.16-1.75-3.906-3.91-3.909H72.93c-2.163,0.003-3.909,1.749-3.91,3.909v9.808
      C69.02,15.874,70.771,17.624,72.93,17.627z M49.923,17.627h9.805c2.159,0,3.91-1.753,3.91-3.91V3.909
      C63.636,1.749,61.889,0,59.728,0h-9.805c-2.163,0.003-3.909,1.749-3.911,3.909v9.808C46.013,15.874,47.764,17.624,49.923,17.627z
      M72.93,70.755h9.805c2.16,0,3.908-1.749,3.91-3.91v-9.804c-0.002-2.161-1.747-3.911-3.91-3.913H72.93
      c-2.163,0.002-3.909,1.752-3.91,3.913v9.804C69.02,69.006,70.771,70.755,72.93,70.755z M95.934,17.627h9.806
      c2.159-0.006,3.906-1.753,3.91-3.91V3.909c0-2.157-1.749-3.909-3.91-3.909h-9.806c-2.16,0-3.91,1.749-3.91,3.909v9.808
      C92.023,15.874,93.775,17.627,95.934,17.627z M118.94,44.19h9.806c2.156,0,3.907-1.749,3.91-3.91v-9.804
      c-0.003-2.164-1.751-3.909-3.91-3.913h-9.806c-2.161,0-3.907,1.756-3.911,3.913v9.804C115.033,42.438,116.781,44.188,118.94,44.19z
      M95.934,70.755h9.806c2.159-0.004,3.906-1.749,3.911-3.91v-9.804c-0.001-2.161-1.75-3.911-3.911-3.913h-9.806
      c-2.16,0.002-3.91,1.752-3.912,3.913v9.804C92.023,69.006,93.775,70.755,95.934,70.755z M118.94,17.627h9.804
      c2.158,0,3.909-1.75,3.909-3.91V3.909C132.652,1.749,130.905,0,128.744,0h-9.804c-2.161,0-3.907,1.752-3.911,3.909v9.808
      C115.033,15.874,116.781,17.621,118.94,17.627z M49.923,44.19h9.805c2.157,0,3.91-1.749,3.91-3.91v-9.804
      c-0.002-2.164-1.75-3.909-3.91-3.909h-9.805c-2.163,0-3.909,1.745-3.911,3.909v9.804C46.013,42.441,47.764,44.19,49.923,44.19z
      M95.934,44.19h9.806c2.159-0.005,3.906-1.752,3.91-3.91v-9.804c0-2.164-1.749-3.909-3.91-3.909h-9.806
      c-2.16,0-3.908,1.745-3.91,3.909v9.804C92.023,42.441,93.775,44.19,95.934,44.19z M3.91,44.19h9.807c2.159,0,3.909-1.745,3.91-3.91
      v-9.804c-0.001-2.164-1.749-3.909-3.91-3.909H3.91c-2.161,0-3.908,1.745-3.91,3.909v9.804C0.002,42.441,1.753,44.19,3.91,44.19z
      M49.921,70.755h9.807c2.159,0,3.908-1.749,3.912-3.91v-9.804c-0.003-2.161-1.751-3.911-3.912-3.913h-9.807
      c-2.161,0.002-3.906,1.752-3.908,3.913v9.804C46.015,69.006,47.762,70.755,49.921,70.755z M151.75,53.13l-32.81-0.002
      c-2.162,0.002-3.911,1.752-3.911,3.913v9.804c0,2.161,1.75,3.91,3.911,3.91h32.81c2.16,0,3.91-1.749,3.91-3.91v-9.804
      C155.658,54.877,153.911,53.13,151.75,53.13z M151.75,26.567h-9.805c-2.161,0-3.908,1.745-3.91,3.909v9.804
      c0.002,2.161,1.749,3.91,3.91,3.91h9.805c2.16,0,3.91-1.749,3.91-3.91v-9.804C155.658,28.313,153.911,26.567,151.75,26.567z
      M151.752,0h-9.807c-2.163,0.003-3.908,1.749-3.91,3.909v9.808c0,2.157,1.749,3.907,3.91,3.91h9.807c2.158,0,3.908-1.753,3.908-3.91
      V3.909C155.658,1.749,153.911,0,151.752,0z M116.279,79.691H39.383c-2.164,0.006-3.909,1.753-3.911,3.914v9.804
      c0.001,2.16,1.749,3.909,3.911,3.909h76.896c2.16,0,3.91-1.749,3.912-3.909v-9.804C120.188,81.444,118.443,79.697,116.279,79.691z
      M26.918,44.19h9.805c2.157-0.002,3.908-1.745,3.91-3.91v-9.804c-0.002-2.164-1.751-3.909-3.91-3.913h-9.805
      c-2.162,0.004-3.911,1.749-3.912,3.913v9.804C23.007,42.441,24.759,44.19,26.918,44.19z M26.916,17.627h9.807
      c2.159-0.006,3.908-1.75,3.908-3.91V3.909C40.63,1.749,38.884,0,36.723,0h-9.807c-2.161,0-3.908,1.749-3.908,3.909v9.808
      C23.008,15.877,24.759,17.627,26.916,17.627z M3.91,17.627h9.807c2.159-0.003,3.909-1.75,3.91-3.91V3.909
      c-0.001-2.16-1.749-3.906-3.91-3.909H3.91C1.749,0,0.002,1.749,0,3.909v9.808C0.002,15.874,1.753,17.624,3.91,17.627z
      M40.631,66.845v-9.804c-0.001-2.168-1.747-3.911-3.908-3.911L3.912,53.128C1.749,53.13,0.003,54.88,0,57.041v9.804
      c0.002,2.161,1.751,3.91,3.912,3.91h32.812C38.88,70.751,40.63,69.008,40.631,66.845z"></path>
      </svg>Enables users to type contents using the mouse
                    </li>
                    <li><svg version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="121.246px" height="122.861px" viewBox="0 0 121.246 122.861" xml:space="preserve">
      <path d="M120.062,5.953c-1.541-1.486-3.993-1.439-5.477,0.1L102.196,18.9c-0.265-0.284-0.52-0.578-0.789-0.857
      C90.691,6.918,75.799-0.004,59.374,0C42.946-0.004,28.054,6.918,17.338,18.043C6.614,29.161-0.002,44.52,0,61.431
      c-0.002,16.91,6.614,32.267,17.338,43.387c0.335,0.348,0.686,0.679,1.029,1.018L8.278,116.298c-1.484,1.539-1.439,3.991,0.1,5.478
      c0.752,0.725,1.721,1.086,2.689,1.086c1.015,0,2.029-0.397,2.788-1.187l10.362-10.746c9.831,7.485,21.998,11.936,35.156,11.932
      h0.015c16.419,0,31.306-6.924,42.019-18.043c10.725-11.12,17.342-26.477,17.34-43.387c0.002-13.646-4.312-26.279-11.611-36.491
      l13.026-13.509C121.646,9.892,121.601,7.439,120.062,5.953z M23.746,100.257c-0.276-0.273-0.559-0.539-0.829-0.818
      C13.556,89.728,7.749,76.306,7.746,61.431c0.002-14.878,5.81-28.3,15.171-38.011c9.371-9.709,22.228-15.669,36.456-15.674
      C73.6,7.751,86.457,13.711,95.83,23.42c0.335,0.348,0.654,0.709,0.979,1.066L78.5,43.475l-6.602-4.03
      c-1.528-0.938-3.255-1.391-4.93-1.388c-3.181-0.007-6.307,1.621-8.087,4.539c-0.905,1.483-1.386,3.191-1.386,4.932v17.729
      l-5.515,5.72c-0.507-0.285-1.083-0.462-1.707-0.462h-9.356c-1.933,0-3.5,1.566-3.5,3.5c0,1.933,1.566,3.5,3.5,3.5h4.758
      L23.746,100.257z M79.765,53.325l10.425,6.362c0.216,0.131,0.403,0.312,0.547,0.534c0.192,0.294,0.273,0.6,0.275,0.924
      c-0.005,0.57-0.269,1.114-0.788,1.454l-22.32,14.533c-0.281,0.183-0.61,0.28-0.938,0.28c-0.945-0.003-1.719-0.774-1.722-1.722
      v-7.309L79.765,53.325z M65.243,57.222v-9.694c0.003-0.318,0.088-0.623,0.255-0.898c0.326-0.536,0.882-0.82,1.471-0.825
      c0.313,0.003,0.598,0.077,0.884,0.251l5.137,3.135L65.243,57.222z M111.001,61.431c-0.002,14.875-5.809,28.297-15.171,38.008
      c-9.373,9.709-22.23,15.672-36.457,15.674c-11.077-0.002-21.313-3.628-29.73-9.81l27.888-28.921
      c0.358,4.906,4.437,8.774,9.434,8.778c1.839,0,3.632-0.536,5.165-1.534l22.328-14.538c2.777-1.818,4.309-4.856,4.302-7.942
      c0.002-1.766-0.501-3.575-1.534-5.158c-0.771-1.183-1.793-2.177-2.997-2.912l-8.953-5.466l16.372-16.979
      C107.529,39.342,110.999,49.948,111.001,61.431z M53.775,49.849c0-1.934-1.566-3.5-3.5-3.5H28.917c-1.932,0-3.499,1.566-3.499,3.5
      c0,1.932,1.566,3.499,3.499,3.499h21.357C52.208,53.348,53.775,51.78,53.775,49.849z M53.775,61.932
      c0-1.933-1.567-3.499-3.499-3.499H35.917c-1.933,0-3.499,1.566-3.499,3.499s1.566,3.502,3.499,3.502h14.358
      C52.208,65.434,53.775,63.864,53.775,61.932z"></path>
      </svg>Stops blinking and flashing of moving elem

                    </li>

                    <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M.3 89.5C.1 91.6 0 93.8 0 96v64V416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64V160 96c0-35.3-28.7-64-64-64H64c-2.2 0-4.4 .1-6.5 .3c-9.2 .9-17.8 3.8-25.5 8.2C21.8 46.5 13.4 55.1 7.7 65.5c-3.9 7.3-6.5 15.4-7.4 24zM48 160H464l0 256c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-256z"/></svg>Compatibility with browsers and assistive technology
                    </li>
                </ul>
                <p>
                    Our mission goal is to be able to support the widest array of browsers and assistive technologies as possible.
                    We have therefore invested efforts to support popular systems with high market share, including Chrome, Firefox, edge, Opera and Safari VoiceOver on a MAC.
                    We have also addressed JAWS and NVDA assistive technologies for Windows and MAC.
                </p>
                
            </div>

            <div class="statement-cont mb-40">
               <h2 class="mb-5"> Technical specifications </h2>
               <p>
                Accessibility of this website relies on the following technologies to work with the particular combination of web browser and any assistive technologies or plugins installed on your computer:
            </p>
            <ul class="implementsList mb-30">
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 32l34.9 395.8L191.5 480l157.6-52.2L384 32H0zm308.2 127.9H124.4l4.1 49.4h175.6l-13.6 148.4-97.9 27v.3h-1.1l-98.7-27.3-6-75.8h47.7L138 320l53.5 14.5 53.7-14.5 6-62.2H84.3L71.5 112.2h241.1l-4.4 47.7z"/></svg>HTML
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M319.9 335.2c-18.2 16.3-30 39.1-35.1 62.9c-5.6 26.3-18.6 51.3-39 71.7c-56.2 56.2-147.4 56.2-203.6 0C14.1 441.7 0 404.9 0 368V192C0 86 86 0 192 0S384 86 384 192c0 56.9-24.8 108-64.1 143.2zM128 192c0-35.3 28.7-64 64-64s64 28.7 64 64v8c0 13.3 10.7 24 24 24s24-10.7 24-24v-8c0-61.9-50.1-112-112-112S80 130.1 80 192v20.2c0 21.2 12 40.6 31 50.1l14 7c1.8 .9 3 2.8 3 4.9c0 1.6-.7 3.2-2 4.2L88.6 309.6c-10.2 8.5-11.6 23.6-3.1 33.8s23.6 11.6 33.8 3.1l37.4-31.2C169 305.1 176 290.1 176 274.2c0-20.2-11.4-38.8-29.5-47.8l-14-7c-2.7-1.4-4.4-4.1-4.4-7.2V192z"/></svg>WAI-ARIA
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 32l34.9 395.8L192 480l157.1-52.2L384 32H0zm313.1 80l-4.8 47.3L193 208.6l-.3.1h111.5l-12.8 146.6-98.2 28.7-98.8-29.2-6.4-73.9h48.9l3.2 38.3 52.6 13.3 54.7-15.4 3.7-61.6-166.3-.5v-.1l-.2.1-3.6-46.3L193.1 162l6.5-2.7H76.7L70.9 112h242.2z"/></svg>CSS
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM243.8 381.4c0 43.6-25.6 63.5-62.9 63.5-33.7 0-53.2-17.4-63.2-38.5l34.3-20.7c6.6 11.7 12.6 21.6 27.1 21.6 13.8 0 22.6-5.4 22.6-26.5V237.7h42.1v143.7zm99.6 63.5c-39.1 0-64.4-18.6-76.7-43l34.3-19.8c9 14.7 20.8 25.6 41.5 25.6 17.4 0 28.6-8.7 28.6-20.8 0-14.4-11.4-19.5-30.7-28l-10.5-4.5c-30.4-12.9-50.5-29.2-50.5-63.5 0-31.6 24.1-55.6 61.6-55.6 26.8 0 46 9.3 59.8 33.7L368 290c-7.2-12.9-15-18-27.1-18-12.3 0-20.1 7.8-20.1 18 0 12.6 7.8 17.7 25.9 25.6l10.5 4.5c35.8 15.3 55.9 31 55.9 66.2 0 37.8-29.8 58.6-69.7 58.6z"/></svg>JavaScript
                </li>
                </ul>
                <p class="mb-20">  
                    These technologies are relied upon for conformance with the accessibility standards used.
                    
                    Use of Third-Party Sites & Components
                    
                    When 3rd party components or websites are used on the website, such as Facebook, Instagram, YouTube, Twitter, chats and others, which are not statement-controlled by us, may present challenges for individuals with disabilities that we are not able to remedy.
                    
                    Here are samples of Accessibility Policies provided from some 3rd party sites:
                </p>
            <ul class="implementsList">
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg><a target="_blank" href="https://www.facebook.com/help/141636465971794/">Facebook accessibility policy </a>
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"/></svg><a target="_blank" href="https://support.google.com/youtube/answer/189278?hl=en">YouTube accessibility policy</a>
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"/></svg><a target="_blank" href="https://help.instagram.com/1178723545597542">Instagram accessibility</a>
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM351.3 199.3v0c0 86.7-66 186.6-186.6 186.6c-37.2 0-71.7-10.8-100.7-29.4c5.3 .6 10.4 .8 15.8 .8c30.7 0 58.9-10.4 81.4-28c-28.8-.6-53-19.5-61.3-45.5c10.1 1.5 19.2 1.5 29.6-1.2c-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3c-9-6-16.4-14.1-21.5-23.6s-7.8-20.2-7.7-31c0-12.2 3.2-23.4 8.9-33.1c32.3 39.8 80.8 65.8 135.2 68.6c-9.3-44.5 24-80.6 64-80.6c18.9 0 35.9 7.9 47.9 20.7c14.8-2.8 29-8.3 41.6-15.8c-4.9 15.2-15.2 28-28.8 36.1c13.2-1.4 26-5.1 37.8-10.2c-8.9 13.1-20.1 24.7-32.9 34c.2 2.8 .2 5.7 .2 8.5z"/></svg><a target="_blank" href="https://help.twitter.com/en/using-x/picture-descriptions">Twitter Accessibility</a>
                </li>
                <li><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg><a target="_blank" href="https://www.linkedin.com/accessibility">LinkedIn Accessibility</a>       
                    
                </li>
            </ul>
            </div>
   
            <div class="statement-cont mb-40">
                <h2 class="mb-5">Physical location accessibility services</h2>
                <p>
                    Our company considers it very important to provide quality, professional, equitable and accessible service to all our customers, including people with disabilities.
                    Physical accessibility In our location you can find
                </p>
                <div id="statement-physical-accessibility">
                                              
                </div>

            </div>
            <div class="statement-cont mb-40">
    <h2 class="mb-5">Contact details of a Compliance Officer</h2>
                <span>
                    It is our intention to resolve any complaint to your satisfaction, however, should you have any questions about our accessibility policy, including any requests to exercise your legal rights, please contact our Compliance Officer:<span id="statement-contact">
                    <ul>
                        <li class="statement-officer-name statemet-list-item-statement-contact">
                    <span class="data-label">Name</span>
                    <span class="data-ans">
                        Lisa Clifford
                    </span>
                </li> <li class="statement-officer-job statemet-list-item-statement-contact">
                    <span class="data-label">Job title</span>
                    <span class="data-ans">
                        Officer
                    </span>
                </li> <li class="statement-officer-phone statemet-list-item-statement-contact">
                    <span class="data-label">Phone number</span>
                    <span class="data-ans">
                        <a href="tel:+447800736948">+447800736948</a>
                    </span>
                </li>  <li class="statement-officer-email statemet-list-item-statement-contact">
                    <span class="data-label">Email address</span>
                    <span class="data-ans">
                        <a href="mailto:lisa.clifford@manybooks.net"> lisa.clifford@manybooks.net</a>
                    </span>
                </li> 
                    </ul>
                </span>
                </span>
            </div>
            <div class="statement-cont">
                <h2>Feedback</h2>
                <span>
                    Despite our efforts to make site browsing accessible for people with disabilities, some pages may yet to be accessible, or the appropriate technological solution has not yet been found it is possible that some pages or sections on this website have Human mistakes, for a variety of reasons. We are dedicated to keep improving the accessibility features and interface of our product.
                    Notice something wrong? you are most welcome to reach out to us at: <span id="statement-company">
                    <ul>
                        <li class="statement-contact-company statemet-list-item-statement-company">
                    <span class="data-label">Company name</span>
                    <span class="data-ans">
                        Advertical Media
                    </span>
                </li> <li class="statement-contact-email statemet-list-item-statement-company">
                    <span class="data-label">Company email support</span>
                    <span class="data-ans">
                        support@Metergram.com
                    </span>
                </li> <li class="statement-contact-phone statemet-list-item-statement-company">
                    <span class="data-label">Company Phone</span>
                    <span class="data-ans">
                        <a href="tel:+1 (904) 999-1179">+1 (904) 999-1179</a>
                    </span>
                </li> <li class="statement-contact-job-title statemet-list-item-statement-company">
                    <span class="data-label">Job title</span>
                    <span class="data-ans">
                        Founder
                    </span>
                </li> <li class="statement-contact-adress statemet-list-item-statement-company">
                    <span class="data-label">Company address street</span>
                    <span class="data-ans">
                        830-13 A1A North #234 Ponte Vedra Beach, FL 32082
                    </span>
                </li> <li class="statement-contact-country statemet-list-item-statement-company">
                    <span class="data-label">Company address country</span>
                    <span class="data-ans">
                        USA
                    </span>
                </li>
                    </ul>
                </span> 
                </span>
                
            </div>
            <div class="statement-cont">
                <h2>Notes</h2>
                <div>
                    <p>
                    Despite our efforts to make site browsing accessible for people with disabilities, some pages may yet to be accessible, or the appropriate technological solution has not yet been found.

                    We continue our efforts to improve site accessibility as much as possible, with the belief and moral commitment to enable the site to be used by the general population, including people with disabilities.
                    </p>
                    <p>
                        you can contact us by using the “Feedback” button and filling out the form. You may also reach out to us by email at : <a href="mailto:lisa.clifford@manybooks.net">lisa.clifford@manybooks.net</a> 
                    </p>  
                    <div id="statement-information">
                        <ul>
                             
                        </ul>
                    </div>             
                </div>
            </div>
        </div>  
        <div id="statement-footer">
            Date of accessibility statement generate: 26 Apr 2024, 15:36 
        </div>  
    </div>
`;